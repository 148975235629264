.project-information{
width: 100%; 
}

.project-information{
display:flex;
flex-direction: column;
align-items: start; 
border: 1px solid gainsboro;
}
.project-information span{
padding: 2px;  
border-bottom: 1px solid gainsboro;
}
.objectives-image{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: 120px;
height: 120px;
}
.objectives-list li{
padding: 10px;
display: inline-block;

}