#footer-copy-write{
font-size: 80%;    
}


.brandlogoFrame{
width: 100%;
display: flex;
flex-direction: row;
align-items: center;    
}

#TopRegistrationLink{
display: flex;
flex-direction: row;
justify-content: space-between;    
}