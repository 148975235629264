.our-strength-list li{
display: inline-block;    
padding: 8px;
border-bottom: 1px solid gainsboro;
}
#position-description{
text-align: justify;   
border: 1px solid gainsboro;
}

.stuff-profile-frame{
width: 100%;  
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 5px;
}