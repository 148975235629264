
.pagenotfound-wrapper{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: 100%;
height: 300px;
background-color: aliceblue;
box-shadow: 2px 2px 2px 2px gray;  
position: relative;
margin: 5px;  
}