.ProductDisplayFrame{
width: 100%;
// height: 500px;
background-color: #ffffff9f; 
display: flex;
flex-direction:column;
justify-content: center; 
align-items: center;
padding: 10px;
}
.ProductDetailFrame{
width: 100%;
height: 100%;
background-color: #10ba3a9e;
color: #fff;
}

.ProductDetailFrame ul li{
display: flex;
padding: 5px;
font-size: 1.2rem;    
font-style: italic;
}

.productLabel{
color: #fff;  
border-radius: 20px;
padding: 5px;  
}