.project-information{
width: 100%; 
}

.project-information{
display:flex;
flex-direction: column;
align-items: start; 
border: 1px solid gainsboro;
}
.project-information span{
padding: 2px;  
border-bottom: 1px solid gainsboro;
}