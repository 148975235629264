.our-strength-list li{
display: inline-block;    
padding: 8px;
border-bottom: 1px solid gainsboro;
}

#about-image{
width: 100%;
height: 550px;    
background-position:50%;
background-repeat: no-repeat;
background-size: cover;
}

#about2-image{
    width: 100%;
    height: 300px;    
    background-position:50%;
    background-repeat: no-repeat;
    background-size: cover; 
    margin: 10px;   
}


.AboutPhotoFrame{
width: 100%;
height: 350px;
background-color: #fff;  
background-repeat: no-repeat;
background-size: cover;  
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: flex-end;
// margin-bottom: 100px;
}

.InnerDivFrame{
width: 30%;
height: 230px;
background-color: #ffffffba;
border-radius: 20%;    
position: relative;
bottom: -20%;
left: 2%;
border-bottom: 3px solid gold;
padding: 5px;
}